<template>
  <div>
    <v-row no-gutters>
      <v-col cols='3' class='ml-2 mt-2'>
        <v-autocomplete
          v-model='customerSelect'
          :items='customerList'
          item-text='name'
          return-object
          label='Cliente'
          placeholder='Selecione um cliente'
          @change='getCustomerLayouts'
        />
      </v-col>
      <v-col v-if='customerSelect' cols='3' class='ml-2 mt-2'>
        <v-autocomplete
          v-model='templateSelect'
          :items='templates'
          item-text='name'
          item-value='name'
          label='Layout'
          color='secondary'
          return-object
          placeholder='Selecione um layout'
          @change='templateChange'
        />
      </v-col>
    </v-row>
    <v-row v-if='templateSelect' no-gutters>
      <v-radio-group v-model='importType' label='Tipo de importação' row @change='recordList = [];'>
        <v-radio label='Base de dados' value='DB' />
        <v-radio label='ZIP' value='ZIP' />
      </v-radio-group>
      <v-row v-if='importType === "DB"' no-gutters>
        <v-btn
          color='secondary'
          class='ml-3 mt-4'
          @click='getTemplate(templateSelect.id)'
        >
          Importar da Base de Dados
        </v-btn>
      </v-row>
      <v-row v-else-if='importType === "ZIP"' align-content='center' no-gutters>
        <v-col cols='4'>
          <v-file-input
            v-model='file'
            show-size
            label='Importar Arquivo Zip'
          />
        </v-col>
        <v-col>
          <v-btn
            color='secondary'
            class='mt-4 ml-3'
            @click='importZipFile'
          >
            Carregar ZIP
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-tabs v-if='(recordList.length >= 0 && startLoad) || errorList.length > 0' v-model='selectedTab' grow>
      <v-tab v-for='(tab, index) in tabs' :key='tab.title'>
        {{ tab.title }}
        <v-btn
          v-if='index === 1 && errorList.length > 0'
          class='ml-3'
          fab
          small
          elevation='2'
          style='width: 26px; height: 26px; color: white;'
          :color='index === 0 ? "success" : "error"'
          :ripple='false'
        >
          {{ errorList.length > 99 ? '99+' : errorList.length }}
        </v-btn>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-if='(recordList.length >= 0 && startLoad) || errorList.length > 0' v-model='selectedTab'>
      <v-tab-item>
        <v-row v-if='(recordList.length >= 0 && startLoad)' no-gutters>
          <v-card flat class='wd-100'>
            <v-card-title class='px-4 py-0'>
              <v-spacer />
              <v-col cols='1' class='py-0'>
                <v-autocomplete
                  v-model='selectedPage'
                  class='mt-6'
                  :items='paginationOptions()'
                  label='Página'
                  @change='loadPageData(true)'
                />
              </v-col>
              <v-col cols='2' class='py-0'>
                <v-select
                  v-model='originFilter'
                  class='mt-6'
                  label='Origem do cadastro'
                  :items='["Ambos", "Somente Openbio Enroll", "Somente Openbio App"]'
                />
              </v-col>
              <v-col cols='2' class='py-0'>
                <v-select
                  v-model='printedDocumentFilter'
                  class='mt-6'
                  label='Documento impresso'
                  :items='["Ambos", "Somente impressos", "Somente não impressos"]'
                />
              </v-col>
              <v-col cols='4' class='mr-4'>
                <v-text-field
                  v-model='search'
                  append-icon='mdi-magnify'
                  :label='$t("SEARCH_IN_RESULTS")'
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
              <v-btn class='ml-3 mt-1 primary' @click='loadPageData(true)'>
                Buscar
              </v-btn>
            </v-card-title>
            <v-data-table
              v-model='selected'
              item-key='composedKey'
              :headers='headers'
              :search='search'
              :items='compRecordList'
              :items-per-page='8'
              :footer-props='{
                itemsPerPageOptions: compItemsPerPageOptions
              }'
              :options.sync='recordListOptions'
              :server-items-length='paginateCustomer ? Number(totalRecords) : recordList.length'
              height='36vh'
              fixed-header
              show-select
              @toggle-select-all='onItemSelected'
              @item-selected='onItemSelected'
              @update:options='loadPageData(false)'
            >
              <template #item.data_aprovacao='{ item }'>
                <span>{{ item.data_aprovacao ? formatDate(new Date(item.data_aprovacao), 'dd/MM/yyyy HH:mm:ss') : '--/--/-- --:--:--'}}</span>
              </template>
              <template #item.origem='{ item }'>
                <v-chip
                  small
                  :color='getOriginColor(item.origem)'
                  dark
                >
                  {{ getOriginText(item.origem) }}
                </v-chip>
              </template>
              <template #item.actions='{ item }'>
                <v-tooltip top>
                  <template #activator='{ on }'>
                    <v-btn
                      icon
                      dense
                      :color='item.printHistory && item.printHistory.length > 0 ? "info" : "gray"'
                      :disabled='item.printHistory && item.printHistory.length === 0'
                      v-on='on'
                      @click='openPrintHistory(item)'
                    >
                      <v-icon small>
                        mdi-history
                      </v-icon>
                    </v-btn>
                  </template>
                  Ver histórico de impressões
                </v-tooltip>
              </template>
              <template #item.preview='{ item }'>
                <v-tooltip top>
                  <template #activator='{ on }'>
                    <v-btn
                      icon
                      dense
                      color='secondary'
                      v-on='on'
                      @click='previewDocument(item)'
                    >
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  Pré-visualizar
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
        <v-row v-else no-gutters>
          <v-card flat class='wd-100'>
            <v-alert class='ma-0' type='warning'>
              Sem resultados válidos. Verifique a aba de erros.
            </v-alert>
          </v-card>
        </v-row>
        <v-row v-if='recordList.length > 0'>
          <v-col>
            <v-btn
              color='secondary'
              class='mr-4'
              :disabled='selected.length === 0'
              @click='showConfirmModal'
            >
              {{$t('NEXT')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <!-- Invalid records Tab -->
      <v-tab-item>
        <v-row v-if='errorList.length > 0' no-gutters>
          <v-card flat class='wd-100'>
            <v-card-title class='px-4 py-0'>
              <v-spacer />

              <v-col cols='4' class='mr-4'>
                <v-text-field
                  v-model='search'
                  append-icon='mdi-magnify'
                  :label='$t("SEARCH")'
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
            </v-card-title>
            <v-data-table
              item-key='name'
              :headers='errorTableHeaders'
              :search='search'
              :items='errorList'
              :items-per-page='8'
              :footer-props='{
                itemsPerPageOptions: [8, 16, 24, 32, -1]
              }'
              height='36vh'
              fixed-header
            >
              <template #item.errors='{ item }'>
                <span v-html='item.errors' />
              </template>

              <template #item.actions='{ item }'>
                <v-tooltip top>
                  <template #activator='{ on }'>
                    <v-btn
                      icon
                      dense
                      color='red'
                      v-on='on'
                      @click='deleteRecord(item)'
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  Excluir cadastro
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
        <v-row v-else no-gutters>
          <v-card flat class='wd-100'>
            <v-alert class='ma-0' type='success'>
              Não há nenhum erro nos cadastros importados!
            </v-alert>
          </v-card>
        </v-row>
        <v-row v-if='errorList.length > 0 && errorXLSX'>
          <v-col>
            <v-btn color='success' class='mr-4' @click='downloadErrorXLSX'>
              <v-icon small class='mr-3'>
                mdi-download
              </v-icon>
              {{ $t('DOWNLOAD_XLSX') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model='printHistoryDialog' width='50%'>
      <v-card v-if='printHistoryDialog' style='background-color: white;'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='secondary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='printHistoryDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              Histórico de impressão - {{ templateSelect.name }} - {{ printHistoryDialogItem.name }}
            </div>
          </v-app-bar>
          <v-list-item-content v-for='(otherPrint, i) in printHistoryDialogItem.printHistory' :key='otherPrint.name' two-line class='px-3 pt-4 pb-0'>
            <v-list-item-subtitle class='mt-2'>
              {{ formatDate(new Date(otherPrint.created_at), 'dd/MM/yyyy HH:mm:ss') }} por <strong>{{ otherPrint.username }}</strong>
            </v-list-item-subtitle>
            <v-divider v-if='i < printHistoryDialogItem.printHistory.length - 1' class='mt-5 mb-0' />
            <div v-else class='mb-5' />
          </v-list-item-content>
        </v-container>
      </v-card>
    </v-dialog>

    <PreviewDialog v-if='previewDialog.show' ref='PreviewDialog' :preview-info='previewDialog' :template-layout='templateLayout' />

    <v-navigation-drawer
      right
      :app='active && (printList.length >= 0 && startLoad) || errorList.length > 0'
      width='20vw'
      :value='(recordList.length >= 0 && startLoad) || errorList.length > 0'
      :permanent='active && (printList.length >= 0 && startLoad) || errorList.length > 0'
    >
      <h3 class='mt-2 text-center color-primary'>Lista de impressão</h3>
      <h5 class='text-center color-secondary'>Total: {{ printList.length }}</h5>
      <v-divider class='mx-6 my-2' />
      <ol class='mt-4'>
        <li v-for='(item, index) in printList' :key='index' style='line-height: 2vh' class='color-secondary'>
          {{ item.name }}
          <v-btn icon x-small @click='removeFromPrintList(item, index)'>
            <v-icon color='error' x-small>mdi-close</v-icon>
          </v-btn>
        </li>
      </ol>
      <v-spacer />
      <template #append>
        <div v-if='printList.length > 0' class='pa-3 text-center'>
          <v-btn color='warning' outlined @click='printList = []; selected = []'>
            <v-icon class='mr-2'>mdi-delete</v-icon>
            Limpar lista
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import { format } from 'date-fns';
  import { base64ToFile } from '../../../util/file';

  export default {
    name: 'ImportPage',
    props: {
      reloadData: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      PreviewDialog: () => import('../components/PreviewDialog/PreviewDialog'),
    },
    computed: {
      compRecordList: function () {
        this.change_is_printed();
        let list = this.recordList;
        if (this.printedDocumentFilter === 'Somente impressos') {
          list = list.filter((l) => l.selectedTemplateHistory.isPrinted === 'Sim');
        } else if (this.printedDocumentFilter === 'Somente não impressos') {
          list = list.filter((l) => l.selectedTemplateHistory.isPrinted === 'Não');
        }

        if (this.originFilter === 'Somente Openbio Enroll') {
          list = list.filter((l) => !l.origem);
        } else if (this.originFilter === 'Somente Openbio App') {
          list = list.filter((l) => l.origem === 'antheus_grafica_app');
        }

        if (this.search && this.search !== '') {
          list = list.filter((l) => l.name.toUpperCase().indexOf(this.search.toUpperCase()) >= 0);
        }

        list.sort((a, b) => {
          if (a.name < b.name) return this.sortList ? -1 : 1;
          if (a.name > b.name) return this.sortList ? 1 : -1;
          return 0;
        });

        list.forEach((item, index) => {
          item.composedKey = `${item.name}_${item.cargo}_${index}`.trim();
        });

        if (list.length > this.pageItems) {
          const startIndex = this.searchIndex === 1 ? 0 : (this.selectedPage - 1) * this.pageItems;
          const endIndex = startIndex + this.pageItems;
          return list.slice(startIndex, endIndex);
        }
        // eslint-disable-next-line
        return list;
      },
      compItemsPerPageOptions: function () {
        if (['PC_BA', 'PM_SC'].includes(this.customerSelect.initials)) {
          return [8, 16, 24, 32, 100, 500];
        }
        if (this.paginateCustomer()) {
          return [8, 16];
        }
        return [8, 16, 24, 32, -1];
      },
    },
    watch: {
      reloadData: function (newVal) {
        if (newVal) {
          if (this.importType === 'DB') {
            this.loadDataFromApi(false);
          } else {
            this.importZipFile();
          }
          this.selected = [];
        }
      },
      search (newVal) {
        this.searchIndex = newVal ? 1 : 0;
      },
    },
    data: function () {
      return {
        selectedPage: 1,
        selected: [],
        search: '',
        file: null,
        headers: [
          { text: 'Nome', value: 'name' },
          { text: 'Pré-visualização', value: 'preview' },
          { text: 'Origem', value: 'origem', align: 'center' },
          { text: 'Documento Impresso', value: 'selectedTemplateHistory.isPrinted', align: 'center' },
          { text: 'Data de validação', value: 'data_aprovacao', align: 'center' },
          { text: 'Data de Impressão', value: 'selectedTemplateHistory.printedOn', align: 'center' },
          // { text: 'RENACH', value: 'renach' },
          { text: '', value: 'actions' },
        ],
        recordList: [],
        customerSelect: '',
        customerList: [],
        templateLayout: null,
        importType: undefined,
        templateSelect: '',
        templates: [],
        printHistoryDialog: false,
        printHistoryDialogItem: {},
        errorList: [],
        errorXLSX: undefined,
        selectedTab: 0,
        errorTableHeaders: [
          { text: 'Nome', value: 'full_name' },
          { text: 'Erros', value: 'errors' },
          { text: 'Ações', value: 'actions' },
        ],
        tabs: [
          { title: 'Válidos' },
          { title: 'Com erro' },
        ],
        TAB_INDEXES: {
          VALIDS: 0,
          ERRORS: 1,
        },
        previewDialog: {},
        printedDocumentFilter: 'Ambos',
        originFilter: 'Ambos',
        recordListOptions: {},
        totalRecords: undefined,
        firstLoad: true,
        startLoad: false,
        printList: [],
        printListVisibility: false,
        sortList: false,
        pageItems: 0,
        searchIndex: 0,
      };
    },
    mounted: function () {
      this.getCustomers();
    },
    methods: {
      change_is_printed: function () {
        let count = 0;
        this.recordList.forEach((x) => {
          if (count < 1) {
            x.selectedTemplateHistory.isPrinted = 'Sim';
          }
          count++;
        });
        return true;
      },
      paginationOptions: function () {
        return Array.from({ length: Math.ceil((this.paginateCustomer() ? this.totalRecords : this.recordList.length) / this.recordListOptions.itemsPerPage) }, (_, index) => index + 1);
      },
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customerList = result.data.customers;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getCustomerLayouts: async function () {
        const customerId = Number(this.customerSelect.id);
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: `/layout/customer-layout/${customerId}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.templates = result.data;
          this.$emit('templates', this.templates);
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      paginateCustomer: function () {
        return ['PC_SC', 'PP_SC', 'PC_BA', 'PM_SC'].includes(this.customerSelect.initials);
      },
      loadPageData: function (updateDtOptions) {
        if (updateDtOptions) {
          this.recordListOptions.page = this.selectedPage;
        } else {
          this.selectedPage = this.recordListOptions.page;
          this.pageItems = this.recordListOptions.itemsPerPage;
        }
        if (this.recordListOptions.sortBy[0] === 'name') {
          // eslint-disable-next-line
          this.sortList = this.recordListOptions.sortDesc[0] ? false : true;
        }
        if (!this.firstLoad && this.paginateCustomer()) {
          this.loadDataFromApi();
        }
        this.firstLoad = false;
        this.searchIndex = 0;
      },
      getTemplate: async function (templateId) {
        try {
          const result = await axios({
            url: `/layout/${templateId}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.templateLayout = result.data.layout;
          this.$emit('updateLayout', this.templateLayout);
        } catch (e) {
          console.log(e); // eslint-disable-line no-console
        }
        await this.loadDataFromApi();
      },
      loadDataFromApi: async function () {
        if (!this.customerSelect) {
          Swal.fire({
            icon: 'warning',
            text: 'É necessário informar um cliente',
            confirmButtonText: 'OK',
          });
          return;
        }

        this.startLoad = true;
        const paginate = this.paginateCustomer();
        this.$store.dispatch('loading/toggleLoading', true);
        this.$forceUpdate();
        try {
          const result = await axios({
            url: '/document/data',
            params: {
              customer: this.customerSelect.id,
              template: this.templateSelect.name,
              page: paginate ? (this.recordListOptions.page || 1) : undefined,
              offset: paginate ? (this.recordListOptions.itemsPerPage || 8) : undefined,
            },
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.recordList = result.data.uploadPeople;
          this.errorList = result.data.errors;
          if (this.errorList.length > 0) {
            this.errorList.forEach((e) => {
              e.errors = e.errors.replace(/\n/g, '<br />');
            });
          }
          this.errorXLSX = result.data.errorXLSX;
          this.totalRecords = result.data.total;
          this.printListVisibility = true;
          this.$store.dispatch('loading/toggleLoading', false);
          this.$forceUpdate();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      resetFilters: function () {
        this.selectedPage = 1;
        this.printedDocumentFilter = 'Ambos';
        this.originFilter = 'Ambos';
      },
      importZipFile: async function () {
        if (!this.file) return;

        const validos = /(\.zip)$/i;
        const fileName = this.file.name;

        if (!validos.test(fileName)) return;

        this.$store.dispatch('loading/toggleLoading');

        this.startLoad = true;
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        // eslint-disable-next-line no-multi-assign
        reader.onload = async (load) => {
          const file = load.currentTarget.result.split(',').pop();
          try {
            const result = await axios({
              url: '/upload',
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'POST',
              responseType: 'json',
              withCredentials: true,
              data: {
                file: file,
                template: this.templateSelect.name,
              },
            });
            this.recordList = result.data.uploadPeople;
            this.printListVisibility = true;
            this.$store.dispatch('loading/toggleLoading');
          } catch (e) {
            console.log(e); /* eslint-disable-line no-console */
            this.$store.dispatch('loading/toggleLoading');
          }
        };
      },
      showConfirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('next');
            this.$emit('selected', this.printList);
            this.printListVisibility = false;
          }
        });
      },
      openPrintHistory: function (item) {
        this.printHistoryDialog = true;
        this.printHistoryDialogItem = item;
      },
      previewDocument: function (item) {
        this.previewDialog = {
          show: true,
          id: item.name,
          values: item,
          template: this.templateSelect,
        };
      },
      formatDate: function (date, dateFormat) {
        return format(date, dateFormat);
      },
      templateChange: function () {
        this.$emit('template', this.templateSelect);
      },
      downloadErrorXLSX: function () {
        const file = base64ToFile(this.errorXLSX, 'erros_importação.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        const a = document.createElement('a');

        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = 'erros_importação.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      deleteRecord: function (record) {
        Swal.fire({
          icon: 'warning',
          title: 'Exclusão de cadastro',
          text: 'Deseja realmente excluir este cadastro?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: '/record',
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'DELETE',
              responseType: 'json',
              withCredentials: true,
              data: {
                recordId: record.id,
                customerId: this.customerSelect.id,
              },
            });
            const index = this.errorList.findIndex((e) => e.id === record.id);
            this.errorList.splice(index, 1);
          }
        });
      },
      getOriginColor: function (origin) {
        return origin ? this.$vuetify.theme.defaults.light.tertiary : this.$vuetify.theme.defaults.light.secondary;
      },
      getOriginText: function (origin) {
        return origin ? 'Openbio App' : 'Openbio Enroll';
      },
      onItemSelected: function (event) {
        if (event.items) {
          if (event.value) {
            this.printList = this.printList.concat(event.items);
          } else {
            this.printList = this.printList.filter((item) => !event.items.some((removeItem) => removeItem.cpf === item.cpf));
          }
        } else {
          // eslint-disable-next-line
          if (event.value) {
            this.printList.push(event.item);
          } else {
            this.printList = this.printList.filter((item) => item.cpf !== event.item.cpf);
          }
        }
        this.printList = this.printList.filter((item, index, self) => index === self.findIndex((obj) => obj.cpf === item.cpf));
      },
      removeFromPrintList: function (item, index) {
        this.selected = this.selected.filter((s) => s.cpf !== item.cpf);
        this.printList.splice(index, 1);
      },
    },
  };
</script>
