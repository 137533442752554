<template>
  <div>
    <v-row>
      <v-col col='12'>
        <v-data-table
          v-model='valuesToSave'
          :headers='headers'
          :items='selectedValues.filter((v) => !v.blank)'
          show-select
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color='primary'
          class='mr-4'
          :disabled='valuesToSave.length === 0'
          @click='savePrintValues'
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import { calculaDigitoMod11 } from '../../../util/mod';

  export default {
    name: 'EndPrintPage',
    props: {
      selected: {
        type: Array,
        required: true,
      },
      sequence: {
        type: String,
        required: true,
      },
      renach: {
        type: Boolean,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      },
      field: {
        type: String,
        required: true,
      },
      customerSelect: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      ...mapGetters('customer', [
        'getCustomer',
      ]),
    },
    mounted: function () {
      this.geValues();
      this.formatListToPrint();
    },
    data: function () {
      return {
        selectedValues: [],
        valuesToSave: [],
        linkRenach: false,
        fieldToSave: '',
        sequenceFormat: '',
        actualSequence: '',
        headers: [
          { text: 'Nome', value: 'name', sortable: false },
          { text: 'Data de Solicitação', value: 'requestDate', sortable: false },
          { text: 'RENACH', value: 'renach', sortable: false },
          { text: 'Documento Impresso', value: 'printed', sortable: false },
          { text: 'Número espelho', value: 'mirrorNumber', sortable: false },
          { text: 'Data da impressão', value: 'printedDate', sortable: false },
        ],
        configLayout: {
          docPerPage: 8,
          marginPaperLeft: 17.92,
          marginPaperRight: 17.92,
          marginPaperTop: 11.67,
          marginPaperBottom: 11.67,
          marginDocLeft: 15,
          marginDocBottom: 11.67,
          maxPerLine: 4,
          externalLeftMargin: 0,
          externalRightMargin: 0,
          externalTopMargin: 0,
          externalBottomMargin: 0,
          width: 297,
          height: 218,
        },
      };
    },
    methods: {
      reverseList: function (value) {
        const valuesAux = JSON.parse(JSON.stringify(value));
        const reversedItems = [];
        const { maxPerLine, documentsPerPage } = this.template.printConfig;
        const blankSpaces = value.length % documentsPerPage;
        if (blankSpaces !== 0) {
          for (let i = 0; i < documentsPerPage - blankSpaces; i++) {
            valuesAux.push({ blank: -(i + 1) });
          }
        }
        const linesLength = valuesAux.length / maxPerLine;

        for (let i = 0; i < linesLength; i++) {
          const start = i * maxPerLine;
          const end = Math.min(start + maxPerLine, valuesAux.length);
          const line = valuesAux.slice(start, end).reverse();
          reversedItems.push(...line);
        }

        return reversedItems;
      },

      showModalFrontAndBack: function () {
        Swal.fire({
          icon: 'success',
          title: 'Impressão enviada com sucesso',
          text: 'Deseja imprimir verso da folha?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('selected', this.reverseList(this.selected));
            this.$emit('next', 2);
          } else {
            this.$emit('next', 1, true);
          }
          this.$emit('linkRenach', this.saveMirrorValue);
        });
      },

      geValues: function () {
        this.selectedValues = this.selected;
        this.sequenceInicial = this.sequence;
        this.actualSequence = this.sequence;
        this.linkRenach = this.renach;
        this.fieldToSave = this.field;
      },

      savePrintValues: async function () {
        this.$store.dispatch('loading/toggleLoading');
        // eslint-disable-next-line arrow-body-style
        const valuesToSaveFormat = this.valuesToSave.map((values) => {
          return {
            ...values,
            selected_value: values[this.fieldToSave],
          };
        });

        try {
          await axios({
            url: '/document/link',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              valuesToSave: valuesToSaveFormat,
              linkRenach: this.linkRenach,
              documentType: this.template.name,
              user: this.getUser.account.id,
              customerInitials: this.getCustomer.initials,
              customerId: this.customerSelect.id,
            },
          });
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Sucesso ao salvar informações',
            confirmButtonText: 'Ok',
          }).then(() => {
            if (this.template.isFrontAndBack) {
              this.showModalFrontAndBack();
            } else {
              this.$emit('next', 1, true);
            }
          });
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading');
        }
      },

      formatListToPrint: function () {
        this.selected.forEach((value) => {
          const formatSequence = this.actualSequence.toString().padStart(10, '0');
          value.mirrorNumber = `${formatSequence}-${calculaDigitoMod11(formatSequence)}`;
          value.printed = 'Não';
          this.actualSequence++;
        });
      },
    },
  };
</script>
