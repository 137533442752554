<template>
  <v-container fluid class='container-fluid'>
    <v-card id='layout'>
      <v-card-title>
        <v-row class='mb-10 mt-3' style=' display:flex; width: 90%;'>
          <v-col cols='10' style='font-weight: bolder; opacity: 0.8; padding-left: 20px;'>
            {{ $t('LAYOUT_CONFIGURATION') }}
            <v-spacer />
          </v-col>
          <v-col cols='2' style='display: flex; align-content: center; justify-content: center;'>
            <v-btn
              color='secondary'
              class='mr-4'
              @click='openForm'
            >
              <v-icon>mdi mdi-plus</v-icon>
              {{$t('NEW')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='2' class='ml-4 mr-5'>
            <v-switch
              v-model='activeFilter'
              :label='activeFilter? $t("ACTIVATED_LAYOUTS") : $t("INACTIVATED_LAYOUTS")'
              :false-label='"desativados"'
              :true-label='"ativos"'
            />
          </v-col>
          <v-col cols='3' class='ml-12'>
            <v-autocomplete
              v-model='customerSelect'
              :items='customerList'
              item-text='name'
              return-object
              label='Cliente'
              placeholder='Selecione um cliente'
              @change='getCustomerLayouts'
            />
          </v-col>
          <v-col cols='4' class='ml-10'>
            <v-text-field
              v-model='searchCards'
              :label='$t("SEARCH_LAYOUT")'
              class='mr-4'
              prepend-icon='mdi-magnify'
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row style='margin-top: 35px;'>
          <v-col
            v-for='(cardDocument, index) in filteredTemplates'
            :key='index'
            cols='12'
            sm='6'
            md='4'
            lg='3'
            xl='2'
          >
            <v-card id='card' class='mx-auto' fill-height @click='editLayout(cardDocument)'>
              <v-container class='image-container'>
                <v-img class='card-image' :src='cardDocument.background_image' />
              </v-container>
              <v-row no-gutters class='d-flex align-center justify-center'>
                <v-tooltip bottom>
                  <template #activator='{on}'>
                    <v-card-subtitle class='card-text' v-on='on'>{{ cardDocument.name }}</v-card-subtitle>
                  </template>
                  <span>{{ cardDocument.name }}</span>
                </v-tooltip>
                <v-menu
                  class='options'
                  bottom
                  right
                  transition='scale-transition'
                >
                  <template class='options-button' #activator='{ on, attrs }'>
                    <v-btn icon v-bind='attrs' v-on='on'>
                      <v-icon class='icon' @click='openMenu(cardDocument)'>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list v-if='activeMenu === cardDocument.id && cardDocument.active'>
                    <v-list-item-group>
                      <v-list-item @click='cloneLayout(cardDocument)'>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('CLONE') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click='deactivate(cardDocument)'>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('DEACTIVATE') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click='exclude(cardDocument)'>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('DELETE') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-list v-if='activeMenu === cardDocument.id && !cardDocument.active'>
                    <v-list-item-group>
                      <v-list-item @click='activate(cardDocument)'>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('ACTIVATE') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model='dialog' max-width='600px'>
      <v-card>
        <v-card-title>
          <span class='headline'>{{ $t('DOCUMENT_FORM') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref='form'>
              <v-row>
                <v-col cols='12'>
                  <v-text-field
                    v-model='form.name'
                    :label='$t("DOCUMENT_NAME")'
                  />
                </v-col>

                <v-col cols='12'>
                  <v-file-input
                    v-model='form.image'
                    :rules='[rules.required]'
                    :label='$t("IMAGE_UPLOAD")'
                    @change='onFileChange'
                  />
                </v-col>

                <v-col cols='6'>
                  <v-text-field
                    v-model.number='form.width'
                    :rules='[rules.required]'
                    :label='$t("WIDTH")'
                    type='number'
                    step='0.01'
                  >
                    <template #append-outer>
                      <span>mm</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols='6'>
                  <v-text-field
                    v-model.number='form.height'
                    :rules='[rules.required]'
                    :label='$t("HEIGHT")'
                    type='number'
                    step='0.01'
                  >
                    <template #append-outer>
                      <span>mm</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols='12'>
                  <v-checkbox
                    v-model='form.frontBackPrint'
                    :label='$t("TWO_SIDED_PRINTING")'
                  />
                </v-col>

                <v-col cols='12'>
                  <v-checkbox
                    v-model='form.linkToStock'
                    :label='$t("LINK_WITH_STOCK")'
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color='secondary' text @click='cleanForm'>{{ $t('CLOSE') }}</v-btn>
          <v-btn color='secondary' slot='activator' @click='submitForm'>{{ $t('SAVE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CanvasDialog
      ref='canvasLayout'
      :create-layout='createLayout'
      :layout-id='layoutId'
      :clone='clone'
      :canvas-image='imageBase64'
      :width='canvasWidth'
      :height='canvasHeight'
      :document-title='layoutTitle ? layoutTitle : form.name'
      :front-back-print='form.frontBackPrint'
      :link-to-stock='form.linkToStock'
      :conversion-x-factor='conversionXFactor'
      :conversion-y-factor='conversionYFactor'
      @dialog-closed='cleanForm'
    />
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'Layout',

    data: function () {
      return {
        template: [],
        createLayout: 0,
        layoutTitle: null,
        layoutId: null,
        allTemplates: [],
        selectedPreview: '',
        itemsTabs: ['Configuração', 'Pré visualização'],
        tabValue: 0,
        renderCanvas: true,
        activeMenu: -1,
        dialog: false,
        showCanvasDialog: false,
        imageBase64: '',
        form: {
          name: '',
          image: null,
          width: null,
          height: null,
          frontBackPrint: false,
          linkToStock: false,
        },
        searchCards: '',
        activeFilter: true,
        clone: false,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
        },
        canvasWidth: 0,
        canvasHeight: 0,
        conversionXFactor: 0.264589,
        conversionYFactor: 0.264594,
        customerList: [],
        customerSelect: '',
      };
    },
    mounted: async function () {
      await this.getAllTemplate();
      await this.getCustomers();
    },
    beforeDestroy: function () {
      if (document.getElementById(this.cssId)) {
        document.getElementById(this.cssId).remove();
      }
    },

    components: {
      CanvasDialog: () => import('./CanvasDialog'),
      // Canvas: () => import('../Documents/components/Canvas/CNH/Canvas'),
    },

    computed: {
      filteredTemplates: function () {
        if (!this.searchCards && !this.activeFilter) {
          return this.template.filter((cardDocument) => cardDocument.active === false);
        }
        if (this.activeFilter && this.searchCards) {
          return this.template.filter((cardDocument) => cardDocument.active === true && cardDocument.name.toLowerCase().includes(this.searchCards.toLowerCase()));
        }
        if (!this.activeFilter && this.searchCards) {
          return this.template.filter((cardDocument) => cardDocument.active === false && cardDocument.name.toLowerCase().includes(this.searchCards.toLowerCase()));
        }
        return this.template.filter((cardDocument) => cardDocument.active === true);
      },
    },

    methods: {
      forceRenderCanvas: function () {
        this.renderCanvas = false;

        this.$nextTick(() => {
          this.renderCanvas = true;
        });
      },
      getAllTemplate: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/layouts',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.template = result.data;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getCustomerLayouts: async function () {
        if (this.customerSelect === 'Todos' || !this.customerSelect) {
          await this.getAllTemplate();
        } else {
          const customerId = Number(this.customerSelect.id);
          try {
            this.$store.dispatch('loading/toggleLoading');
            const result = await axios({
              url: `/layout/customer-layout/${customerId}`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'GET',
              responseType: 'json',
              withCredentials: true,
            });
            this.template = result.data;
            this.$store.dispatch('loading/toggleLoading');
          } catch (e) {
            console.log(e); /* eslint-disable-line no-console */
            this.$store.dispatch('loading/toggleLoading');
          }
        }
      },
      updateCanvasDimensions: function () {
        this.canvasWidth = this.form.width / this.conversionXFactor;
        this.canvasHeight = this.form.height / this.conversionYFactor;
      },
      cleanForm: async function () {
        this.clone = false;
        this.layoutId = null;
        this.createLayout = 0;
        this.layoutTitle = null;
        this.imageBase64 = '';
        this.form = {
          name: '',
          image: null,
          width: null,
          height: null,
          frontBackPrint: false,
          linkToStock: false,
        };
        this.dialog = false;
      },
      openForm: function () {
        this.dialog = true;
      },
      onFileChange: function (file) {
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (img) => {
            this.imageBase64 = img.target.result;
          };

          reader.onerror = (error) => {
            console.log('onload image ', error); /* eslint-disable-line no-console */
          };
        }
      },
      openMenu: function (cardDocument) {
        this.activeMenu = cardDocument.id;
      },
      cloneLayout: function (cardDocument) {
        this.createLayout = 0;
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja clonar o layout?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const card = this.template.find((item) => item === cardDocument);
            this.layoutId = Number(card.id);
            this.clone = true;
            this.createLayout = 1;
            this.dialog = false;
            this.$refs.canvasLayout.openDialog();
          }
        });
      },
      editLayout: function (cardDocument) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja editar o layout?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const card = this.template.find((item) => item === cardDocument);
            this.layoutId = Number(card.id);
            this.layoutTitle = card.name;
            this.createLayout = 2;
            this.dialog = false;
            this.$refs.canvasLayout.openDialog();
          }
        });
      },
      deactivate: async function (cardDocument) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja desativar o layout?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const card = this.template.filter((item) => item === cardDocument);
              this.$store.dispatch('loading/toggleLoading');
              await axios({
                url: '/layout/update',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                data: {
                  layout: {
                    layout: {
                      id: Number(card[0].id),
                      active: false,
                      update: true,
                      activationChange: true,
                    },
                  },
                },
                responseType: 'json',
                withCredentials: true,
              });
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
            }
            this.dialog = false;
            this.getAllTemplate();
          }
        });
      },
      activate: async function (cardDocument) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja ativar o layout?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const card = this.template.filter((item) => item === cardDocument);
            try {
              this.$store.dispatch('loading/toggleLoading');
              await axios({
                url: '/layout/update',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                data: {
                  layout: {
                    layout: {
                      id: Number(card[0].id),
                      active: true,
                      update: true,
                      activationChange: true,
                    },
                  },
                },
                responseType: 'json',
                withCredentials: true,
              });
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
            }
            this.dialog = false;
            this.getAllTemplate();
          }
        });
      },
      exclude: function (cardDocument) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja excluir o layout?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const card = this.template.filter((item) => item === cardDocument);
            try {
              this.$store.dispatch('loading/toggleLoading');
              await axios({
                url: '/layout',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'DELETE',
                data: {
                  id: Number(card[0].id),
                },
                responseType: 'json',
                withCredentials: true,
              });
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
            }
            this.dialog = false;
            this.getAllTemplate();
          }
        });
      },
      submitForm () {
        if (this.$refs.form.validate()) {
          this.layoutId = null;
          this.createLayout = 0;
          this.clone = false;
          this.form.width = Number(this.form.width);
          this.updateCanvasDimensions();
          // this.showCanvasDialog = true;
          this.$nextTick(() => {
            this.$refs.canvasLayout.openDialog();
          });
          this.dialog = false;
        }
      },
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customerList = result.data.customers;
          this.customerList.unshift('Todos');
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };
</script>
<style>
  .container-fluid {
    max-width: 100% !important;
  }

  #card {
    cursor: pointer;
    width: 220px;
    height: 400px;
  }

  .image-container {
    padding-top: 10px;
    height: 83%;
    position: relative;
    margin-bottom: 0px;
    background-color: #f2edf9 !important;
  }

  .card-image {
    object-fit: contain;
  }

  .card-text {
    white-space: nowrap;
    margin-left: 10px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 10px;
    width: 78%;
    max-height: 60px;
  }

  .v-row {
    position: relative;
  }
</style>
