<template>
  <v-card
    :id='id'
    :height='getHeightCanvas'
    :width='getWidthCanvas'
  >
    <div :id='getCanvaId' ref='canvasLayout' class='layout' :height='getHeightCanvas' :width='getWidthCanvas' />
  </v-card>
</template>

<script>
  import validateCpf from '../../../../../util/cpf';

  export default {
    name: 'Canvas',
    defaultLayout: 0,
    props: {
      templateLayout: {
        type: Object,
        required: true,
      },
      values: {
        type: Object,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      },
      id: {
        type: Number | String, // eslint-disable-line
        required: true,
      },
      customTemplate: {
        type: Object,
        default: null,
      },
    },
    data: function () {
      return {
        jsonValues: [],
        widthCanvas: '500',
        heightCanvas: '600',
      };
    },
    watch: {
      template: function () {
        this.fillLayout();
      },
    },
    mounted: async function () {
      await this.fillLayout();
    },
    computed: {
      getCanvaId: function () {
        return `canva_${this.id}`;
      },
      getWidthCanvas: function () {
        return this.widthCanvas;
      },
      getHeightCanvas: function () {
        return this.heightCanvas;
      },
    },

    methods: {
      fillLayout: function () {
        const canvas = this.$refs.canvasLayout;
        const template = this.templateLayout;

        canvas.style.width = `${template.width}px`;
        canvas.style.height = `${template.height}px`;

        this.widthCanvas = `${template.width}px`;
        this.heightCanvas = `${template.height}px`;

        this.$emit('isFrontAndBack', template.front_back_print);
        // this.$emit('paperLayout', template.id || null);
        this.$emit('pageLayout', true);
        this.$emit('fieldToSave', template.key_field_id || null);
        this.$emit('saveMirror', false);
        this.$emit('sizeLayout', {
          width: `${template.width}mm`,
          height: `${template.height}mm`,
        });

        const divBackground = document.createElement('img');
        divBackground.id = 'background';
        if (this.values && !this.values.blank) {
          divBackground.className = 'background';
          divBackground.src = template.background_image;
        }
        divBackground.style.width = `${template.width}px`;
        divBackground.style.height = `${template.height}px`;
        divBackground.style.position = 'absolute';
        divBackground.style.left = 0;
        divBackground.style.top = 0;
        divBackground.style.padding = '0px';

        canvas.appendChild(divBackground);

        this.$forceUpdate();
        template.layout_fields.forEach((field) => {
          let item = null;
          if (field.type_id === 1 && this.mustShowLabel(field.name)) {
            item = document.createElement('span');
            item.textContent = field.name;
            item.textContent = field.upper_case ? field.name.toUpperCase() : field.name;
          } else if (field.type_id === 2) {
            item = document.createElement('span');
            let valueVariable = this.values[field.name.toLowerCase()];
            if (field.name.toLowerCase() === 'assinatura_impossibilitada') {
              valueVariable = valueVariable === 'true' ? 'Assinatura impossibilitada' : '';
            }
            if (field.name.toLowerCase() === 'validade') {
              valueVariable = valueVariable || 'Indeterminada`';
            }
            if (field.name.toLowerCase() === 'cpf') {
              valueVariable = valueVariable.replace(/\D/g, '');
              valueVariable = valueVariable.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
              if (!validateCpf(valueVariable)) {
                const name = this.values.name || this.values.nome || this.values.full_name || this.values.fullName || this.values.nomeCompleto || this.values.nome_completo;
                this.$emit('validationError', { title: 'CPF Inválido', text: `CPF inválido para o cadastro ${name}. Verifique o CPF, número de caracteres do layout e pontuações.` });
              }
            }
            if (valueVariable) {
              item.textContent = field.upper_case ? valueVariable.toUpperCase() : valueVariable;
            }
          } else if (field.type_id === 3) {
            item = document.createElement('img');
            if (this.values[field.description.toLowerCase()] === 'foto') {
              item.src = this.base64ToImage(this.values[field.description.toLowerCase()]);
            } else {
              item.src = this.base64ToImage(this.values[field.description]);
            }
            if (field.border_radius !== 0) {
              item.style.borderRadius = `${field.border_radius}px`;
              item.style.overflow = 'hidden';
              item.style.objectFit = 'cover';
              item.style.contain = 'fit';
              item.style.display = 'block';
            }
          }
          const x = field.absolute_x;
          const y = field.absolute_y;

          item.style.width = `${field.width}px`;
          item.style.height = `${field.height}px`;
          item.style.position = 'absolute';
          item.style.left = `${x}mm`;
          item.style.top = `${y}mm`;
          item.style.fontSize = `${field.font_size}pt`;
          item.style.fontFamily = `${field.font}`;

          if (field.line_height) {
            const formattedString = field.line_height.replace(',', '.');
            item.style.lineHeight = parseFloat(formattedString);
          }
          if (field.rotate !== 0) {
            item.style.transform = `rotate(${field.rotate}deg)`;
          }
          if (field.font_weight) {
            item.style.fontWeight = field.font_weight;
          }
          if (field.upper_case) {
            item.style.textTransform = 'uppercase';
          }
          if (field.white_space) {
            item.style.whiteSpace = field.white_space;
          }
          if (field.text_align) {
            item.style.textAlign = field.text_align;
          }
          if (field.color) {
            item.style.color = `${field.color}`;
          }
          if (field.letter_spacing) {
            item.style.letterSpacing = `${field.letter_spacing}`;
          }
          item ? canvas.appendChild(item) : true;  // eslint-disable-line
          this.$forceUpdate();
        });
      },
      base64ToImage: function (img) {
        if (img.substring(0, 4).includes('http')) return img;
        return `data:image/jpg;base64,${img}`;
      },
      mustShowLabel: function (title) {
        if (title !== '(APOSENTADO)' && title !== '(INATIVO)' && title !== '(RESERVA)' && title !== '(REFORMADO)') {
          return true;
        }
        if (['APSP', 'APCP'].includes(this.values.infoPorte)) {
          if (this.template.name.indexOf('BOMBEIRO') >= 0 || this.template.name.indexOf('PM') >= 0) {
            return (title === '(RESERVA)' && this.values.reservaReforma && this.values.reservaReforma.toUpperCase() === 'RESERVA')
              || (title === '(REFORMADO)' && this.values.reservaReforma && this.values.reservaReforma.toUpperCase() === 'REFORMADO');
          }
          return true;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .layout {
    position: absolute;
  }
  .theme--light >>> .v-card {
    color: inherit;
  }
  .background {
    position: absolute;
  };
  @font-face {
    font-family: crystal;
    src: url(../../../../../assets/fonts/crystal.TTF);
  }
  @font-face {
    font-family: ocrbstd;
    src: url(../../../../../assets/fonts/OCR_B_Std_Regular.otf);
  }
</style>
