<template>
  <v-row justify='center'>
    <v-dialog
      v-model='dialog'
      fullscreen
      hide-overlay
      :scrim='false'
      transition='dialog-bottom-transition'
      persistent
      @opened='loadImage'
    >
      <v-card>
        <v-toolbar
          dark
          color='secondary'
        >
          <v-btn
            icon
            @click='closeDialog'
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-text-field
              v-model='title'
              single-line
              flat
              plain
              hide-details
              class='toolbar-title-input'
            />
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click='settingsDialog = true'
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn
              backgournd-color='secondary'
              text
              @click='confirmSaveSettings'
            >
              {{$t('SAVE')}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-dialog v-model='settingsDialog' max-width='1100px'>
          <v-toolbar dark color='primary' class='elevation-4 toolbar-margin'>
            <v-btn icon @click='settingsDialog = false'>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class='text-center flex-grow-1' style='margin-right:65px;'>{{ $t('DOCUMENT_CONFIGURATION') }}</v-toolbar-title>
          </v-toolbar>
          <v-card>
            <v-tabs centered grow>
              <v-tab>{{ $t('INTERNAL_SHEET_CONFIGURATIONS') }}</v-tab>
              <v-tab>{{ $t('OUTER_MARGINS_OF_SHEET') }}</v-tab>
              <v-tab>{{ $t('PRINT_SETTINGS') }}</v-tab>
              <v-tab-item>
                <v-container class='fill-height'>
                  <v-row class='d-flex justify-center align-center my-3'>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.width'
                        :label='$t("WIDTH")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.height'
                        :label='$t("HEIGHT")'
                        type='height'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.marginLeft'
                        :label='$t("LEFT_MARGIN")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                  </v-row>
                  <v-row class='d-flex justify-center align-center my-3'>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.marginRight'
                        :label='$t("RIGHT_MARGIN")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.marginTop'
                        :label='$t("TOP_MARGIN")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='3'>
                      <v-text-field
                        v-model='internalSettings.marginBottom'
                        :label='$t("BOTTOM_MARGIN")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-row class='d-flex justify-center align-center my-5 mb-10'>
                  <v-col cols='3'>
                    <v-text-field
                      v-model='internalSettings.distanceToLeftDocument'
                      :label='$t("DISTANCE_FROM_LEFT_DOCUMENT")'
                      type='number'
                      class='mx-4'
                      suffix='mm'
                      :min='0'
                    />
                  </v-col>
                  <v-col cols='3'>
                    <v-text-field
                      v-model='internalSettings.distanceToBottomDocument'
                      :label='$t("DISTANCE_FROM_DOCUMENT_BELOW")'
                      type='number'
                      class='mx-4'
                      suffix='mm'
                      :min='0'
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-container class='fill-height'>
                  <v-row class='d-flex justify-center align-center my-3'>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='externalSettings.marginLeft'
                        :label='$t("LEFT")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='externalSettings.marginRight'
                        :label='$t("RIGHT")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                  </v-row>
                  <v-row class='d-flex justify-center align-center my-3 mb-10'>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='externalSettings.marginTop'
                        :label='$t("TOP")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='externalSettings.marginBottom'
                        :label='$t("BOTTOM")'
                        type='number'
                        class='mx-8'
                        suffix='mm'
                        :min='0'
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container class='fill-height'>
                  <v-row class='d-flex justify-center align-center my-3 mb-10'>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='printSettings.documentsPerPage'
                        :label='$t("DOCUMENTS_PER_PAGE")'
                        type='number'
                        class='mx-8'
                        :min='0'
                      />
                    </v-col>
                    <v-col cols='4'>
                      <v-text-field
                        v-model='printSettings.documentsPerLine'
                        :label='$t("DOCUMENTS_PER_LINE")'
                        type='number'
                        class='mx-8'
                        :min='0'
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
            <v-card-actions class='justify-end'>
              <v-btn color='primary' @click='settingsDialog = false'>{{ $t('SAVE') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-container fluid class='flex-grow-1'>
          <v-row>
            <v-col cols='2'>
              <v-card class='full-height-card'>
                <v-card-title class='d-flex justify-center'>
                  {{ $t('AVAILABLE_FIELDS') }}
                </v-card-title>
                <v-card-text>
                  <v-list class='buttons-list'>
                    <v-list-item>
                      <v-btn class='form-button' @click='addTextarea(1)'>
                        <v-icon left class='icon'>mdi mdi-form-textarea</v-icon>
                        <div class='button-text'>{{ $t('TITLE') }}</div>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn class='form-button' @click='addTextarea(2)'>
                        <v-icon left class='icon'>mdi-form-textbox</v-icon>
                        <div class='button-text'>{{ $t('TEXT') }}</div>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn class='form-button' @click='addImageField()'>
                        <v-icon left class='icon'>mdi-account-box-outline</v-icon>
                        <div class='button-text'>{{ $t('IMAGE') }}</div>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='6'>
              <v-card class='center-card'>
                <v-card-text ref='card'>
                  <div class='canvas-container'>
                    <canvas
                      id='canvasLayout'
                      ref='canvasLayout'
                      :width='canvasWidth'
                      :height='canvasHeight'
                      @mousemove='onCanvasMouseMove'
                    />
                    <div
                      v-for='(textarea, index) in textareas'
                      :key='"text" + index'
                      class='draggable'
                      :style='fieldCSS(textarea)'
                    >
                      <div class='drag-handle' @mousedown.stop='startDrag($event, textarea)'>
                        <span class='mdi mdi-cursor-move' />
                      </div>
                      <textarea
                        v-model='textarea.name'
                        :style='textAreaCSS(textarea)'
                        :maxlength='textarea.character_limit'
                        @mousedown.stop='startResize($event, textarea)'
                      >
                        <div class='resize-handle' @mousedown.stop='startResize($event, textarea)'></div>
                      </textarea>
                    </div>
                    <div
                      v-for='(textarea, index) in titles'
                      :key='"title" + index'
                      class='draggable'
                      :style='fieldCSS(textarea)'
                    >
                      <div class='drag-handle' @mousedown.stop='startDrag($event, textarea)'>
                        <span class='mdi mdi-cursor-move' />
                      </div>
                      <textarea
                        :ref='`title${index}`'
                        v-model='textarea.name'
                        :style='textAreaCSS(textarea)'
                        :maxLength='textarea.character_limit'
                        @input='handleInputChange(textarea)'
                        @mousedown.stop='startResize($event, textarea)'
                      >
                        <div class='resize-handle' @mousedown.stop='startResize($event, textarea)'></div>
                      </textarea>
                    </div>
                    <div
                      v-for='(textarea, index) in imageFields'
                      :key='"image" + index'
                      class='draggable'
                      :style='fieldCSS(textarea)'
                    >
                      <div class='drag-handle' @mousedown.stop='startDrag($event, textarea)'>
                        <span class='mdi mdi-cursor-move' />
                      </div>
                      <textarea
                        v-model='textarea.name'
                        :style='textAreaCSS(textarea)'
                        @mousedown.stop='startResize($event, textarea)'
                      >
                        <div class='resize-handle' @mousedown.stop='startResize($event, textarea)'></div>
                      </textarea>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='4'>
              <v-card class='full-height-card'>
                <v-card-title class='d-flex justify-center'>
                  {{ $t('DOCUMENT_SIZE') }}
                  <v-row class='mt-10' style='display: flex; justify-content: center;'>
                    <v-col class='mr-8' cols='4'>
                      <v-text-field
                        v-model='imageWidth'
                        :rules='[rules.required]'
                        :label='$t("WIDTH")'
                        type='number'
                        step='0.01'
                        suffix='mm'
                      />
                    </v-col>

                    <v-col cols='4'>
                      <v-text-field
                        v-model='imageHeight'
                        :rules='[rules.required]'
                        :label='$t("HEIGHT")'
                        type='number'
                        step='0.01'
                        suffix='mm'
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-title class='d-flex justify-center'>
                  {{ $t('INSERTED_FIELDS') }}
                </v-card-title>
                <v-card-text>
                  <v-expansion-panels>
                    <div v-if='titles.length > 0' class='block-title'>{{ $t('TITLE_FIELDS') }}</div>
                    <v-expansion-panel v-for='(textarea,index) in titles' :key='"title" + index'>
                      <v-expansion-panel-header>
                        <v-text-field
                          v-model='textarea.name'
                          flat
                          solo
                          compact
                          single-line
                          hide-details
                          class='title-text-field'
                          :placeholder='textarea.placeholder'
                        />
                        <v-btn icon style='max-width: 30px; padding-top: 3px;' @click.stop='deleteTitleField(index)'>
                          <v-icon color='error'>mdi mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model.number='textarea.absolute_x'
                              type='number'
                              label='x'
                              suffix='mm'
                              @input='updatePositionFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model.number='textarea.absolute_y'
                              type='number'
                              label='y'
                              suffix='mm'
                              @input='updatePositionFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.width_mm'
                              type='number'
                              :label='$t("WIDTH")'
                              suffix='mm'
                              @input='updateResizeFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.height_mm'
                              type='number'
                              :label='$t("HEIGHT")'
                              suffix='mm'
                              @input='updateResizeFromInput(textarea)'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='3'>
                            <v-select
                              v-model='textarea.font'
                              :items='fonts'
                              :label='$t("TEXT_FONT")'
                              item-text='name'
                              item-value='family'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.font_size'
                              type='number'
                              :label='$t("FONT_SIZE")'
                              suffix='pt'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.break_line_after'
                              :label='$t("BREAK_LINE_AFTER")'
                              type='number'
                              :min='1'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.rotate'
                              :label='$t("ROTATE")'
                              type='number'
                              step='90'
                              :min='0'
                              suffix='°'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='5'>
                            <v-dialog v-model='colorPicker' max-width='290' @click:outside='colorPicker = false'>
                              <v-color-picker v-model='textarea.color' />
                            </v-dialog>
                            <v-text-field
                              :value='textarea.color'
                              :label='$t("COLOR")'
                              readonly
                              append-outer-icon='mdi mdi-brush-outline'
                              @click:append-outer='colorPicker = true'
                              @click='colorPicker = true'
                            />
                          </v-col>
                          <v-col cols='4'>
                            <v-text-field
                              v-model='textarea.character_limit'
                              :label='$t("NUMBER_OF_LETTERS")'
                              type='number'
                              :min='1'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class='d-flex align-center'>
                            <v-checkbox
                              v-model='textarea.upper_case'
                              :label='$t("UPPER_CASE")'
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <div v-if='textareas.length > 0' class='block-title'>{{ $t('TEXT_FIELDS') }}</div>
                    <v-expansion-panel v-for='(textarea,index) in textareas' :key='"text" + index'>
                      <v-expansion-panel-header>
                        <v-text-field
                          v-model='textarea.name'
                          flat
                          solo
                          compact
                          single-line
                          hide-details
                          class='title-text-field'
                          :placeholder='textarea.placeholder'
                        />
                        <v-btn icon style='max-width: 30px; padding-top: 3px;' @click.stop='deleteTextField(index)'>
                          <v-icon color='error'>mdi mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.absolute_x'
                              type='number'
                              label='x'
                              suffix='mm'
                              @input='updatePositionFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.absolute_y'
                              type='number'
                              label='y'
                              suffix='mm'
                              @input='updatePositionFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.width_mm'
                              type='number'
                              :label='$t("WIDTH")'
                              suffix='mm'
                              @input='updateResizeFromInput(textarea)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.height_mm'
                              type='number'
                              :label='$t("HEIGHT")'
                              suffix='mm'
                              @input='updateResizeFromInput(textarea)'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.font_size'
                              type='number'
                              :label='$t("FONT_SIZE")'
                              suffix='pt'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-select
                              v-model='textarea.font'
                              :items='fonts'
                              :label='$t("TEXT_FONT")'
                              item-text='name'
                              item-value='family'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-select
                              v-model='textarea.font_weight'
                              :label='$t("FONT_WEIGHT")'
                              :items='["normal", "bold", "bolder", "lighter", "100", "200", "300", "400", "500", "600", "700", "800", "900"]'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-select
                              v-model='textarea.text_align'
                              :label='$t("TEXT_ALIGN")'
                              :items='["left", "right", "center", "justify"]'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.line_height'
                              :label='$t("LINE_HEIGHT")'
                              type='number'
                              step='0.1'
                              :min='0'
                              suffix='mm'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.character_limit'
                              :label='$t("CHARACTER_LIMIT")'
                              type='number'
                              :min='1'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.break_line_after'
                              :label='$t("BREAK_LINE_AFTER")'
                              type='number'
                              :min='1'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-select
                              v-model='textarea.white_space'
                              :label='$t("WHITE_SPACE")'
                              :items='["normal", "nowrap", "pre", "pre-line", "pre-wrap", "break-space"]'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.rotate'
                              :label='$t("ROTATE")'
                              type='number'
                              step='90'
                              :min='0'
                              suffix='°'
                            />
                          </v-col>
                          <v-col cols='4'>
                            <v-text-field
                              v-model='textarea.letter_spacing'
                              :label='$t("LETTER_SPACING")'
                              type='number'
                              step='0.1'
                              :min='0'
                              suffix='mm'
                            />
                          </v-col>
                          <v-col cols='5'>
                            <v-dialog v-model='colorPicker' max-width='290' @click:outside='colorPicker = false'>
                              <v-color-picker v-model='textarea.color' />
                            </v-dialog>
                            <v-text-field
                              :value='textarea.color'
                              :label='$t("COLOR")'
                              readonly
                              append-outer-icon='mdi mdi-brush-outline'
                              @click:append-outer='colorPicker = true'
                              @click='colorPicker = true'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class='d-flex align-center'>
                            <v-checkbox
                              v-model='textarea.is_checked'
                              :label='$t("IDENTIFIER_FIELD")'
                              @change='changeCheckbox(index)'
                            />
                          </v-col>
                          <v-col class='d-flex align-center'>
                            <v-checkbox
                              v-model='textarea.upper_case'
                              :label='$t("UPPER_CASE")'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class='d-flex align-center'>
                            <div style='font-size: 17px'>{{ $t('ADD_DICTIONARY') }}</div>
                            <v-btn icon style='margin-left: 20px;' @click='addDictionary(textarea)'>
                              <v-icon>mdi mdi-plus-circle-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row v-for='(code, codeIndex) in textarea.code_dictionary' :key='"code" + codeIndex' class='d-flex align-center'>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='textarea.code_dictionary[codeIndex].code'
                              :label='$t("CODE")'
                            />
                          </v-col>
                          <v-col cols='8'>
                            <v-text-field
                              v-model='textarea.code_dictionary[codeIndex].description'
                              :label='$t("CODE_DESCRIPTION")'
                            />
                          </v-col>
                          <v-col cols='1'>
                            <v-btn icon @click='deleteDictionaryItem(index, codeIndex)'>
                              <v-icon color='error'>mdi mdi-delete-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <div v-if='imageFields.length > 0' class='block-title'>{{ $t('IMAGE_FIELDS') }}</div>
                    <v-expansion-panel v-for='(imageField,index) in imageFields' :key='"image" + index'>
                      <v-expansion-panel-header>
                        <v-text-field
                          v-model='imageField.name'
                          flat
                          solo
                          compact
                          single-line
                          hide-details
                          class='title-text-field'
                          :placeholder='imageField.placeholder'
                        />
                        <v-btn icon style='max-width: 30px; padding-top: 3px;' @click.stop='deleteImageField(index)'>
                          <v-icon color='error'>mdi mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.absolute_x'
                              type='number'
                              label='x'
                              suffix='mm'
                              @input='updatePositionFromInput(imageField)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.absolute_y'
                              type='number'
                              label='y'
                              suffix='mm'
                              @input='updatePositionFromInput(imageField)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.width_mm'
                              type='number'
                              :label='$t("WIDTH")'
                              suffix='mm'
                              @input='resizeImagefromInput(imageField)'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.height_mm'
                              type='number'
                              :label='$t("HEIGHT")'
                              suffix='mm'
                              @input='resizeImagefromInput(imageField)'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.border_radius'
                              :label='$t("BORDER_RADIUS")'
                              type='number'
                              :min='0'
                            />
                          </v-col>
                          <v-col cols='3'>
                            <v-text-field
                              v-model='imageField.rotate'
                              :label='$t("ROTATE")'
                              type='number'
                              step='90'
                              :min='0'
                              suffix='°'
                            />
                          </v-col>
                          <v-col cols='6'>
                            <v-text-field
                              v-model='imageField.default_url'
                              :label='$t("DEFAULT_IMAGE_URL")'
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols='12'>
                            <v-text-field
                              v-model='imageField.description'
                              :label='$t("IMAGE_DESCRIPTION")'
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: 'CanvasDialog',
    data () {
      return {
        dialog: false,
        title: '',
        insertedFields: [],
        titles: [],
        textareas: [],
        isDragging: false,
        isResizing: false,
        currentTextarea: null,
        currentImageResizable: null,
        isResizingImage: false,
        imageFields: [],
        isDraggingImage: false,
        currentImageDraggable: null,
        checkboxIndex: -1,
        colorPicker: false,
        internalSettings: {
          width: 0,
          height: 0,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 0,
          marginTop: 0,
          distanceToBottomDocument: 0,
          distanceToLeftDocument: 0,
        },
        externalSettings: {
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          marginBottom: 0,
        },
        printSettings: {
          documentsPerPage: 0,
          documentsPerLine: 0,
        },
        settingsDialog: false,
        cloneImage: null,
        cloneWidth: null,
        cloneHeight: null,
        layoutIdentifierField: null,
        fieldsDeleted: [],
        fieldsDictionaryDeleted: [],
        cloneLayout: null,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
        },
        fonts: [
          { name: 'Arial', family: 'Arial, sans-serif' },
          { name: 'Arial Black', family: '"Arial Black", sans-serif' },
          { name: 'Comic Sans MS', family: '"Comic Sans MS", cursive' },
          { name: 'Courier New', family: '"Courier New", monospace' },
          { name: 'Georgia', family: 'Georgia, serif' },
          { name: 'Impact', family: 'Impact, sans-serif' },
          { name: 'Times New Roman', family: '"Times New Roman", serif' },
          { name: 'Trebuchet MS', family: '"Trebuchet MS", sans-serif' },
          { name: 'Verdana', family: 'Verdana, sans-serif' },
          { name: 'Tahoma', family: 'Tahoma, sans-serif' },
          { name: 'Palatino Linotype', family: '"Palatino Linotype", "Book Antiqua", serif' },
          { name: 'Lucida Console', family: '"Lucida Console", Monaco, monospace' },
          { name: 'Gill Sans', family: '"Gill Sans", sans-serif' },
          { name: 'Helvetica', family: 'Helvetica, sans-serif' },
          { name: 'Lucida Sans', family: '"Lucida Sans", "Lucida Grande", sans-serif' },
          { name: 'Optima', family: 'Optima, sans-serif' },
          { name: 'Bookman', family: 'Bookman, serif' },
          { name: 'Garamond', family: 'Garamond, serif' },
          { name: 'Geneva', family: 'Geneva, sans-serif' },
          { name: 'Lucida Bright', family: '"Lucida Bright", serif' },
          { name: 'Lucida Sans Typewriter', family: '"Lucida Sans Typewriter", "Lucida Typewriter", monospace' },
          { name: 'Copperplate', family: 'Copperplate, serif' },
          { name: 'Brush Script MT', family: '"Brush Script MT", cursive' },
          // Personalizadas
          { name: 'MarkPro', family: 'markpro' },
          { name: 'MarkPro Bold', family: 'markprobold' },
          { name: 'Arial Regular', family: 'arial regular' },
          { name: 'Tahoma', family: 'tahoma' },
          { name: 'Tahoma Bold', family: 'tahoma bold' },
          { name: 'Crystal', family: 'crystal' },
          { name: 'OCR B Std', family: 'ocrbstd' },
        ],
        canvasX: 0,
        canvasY: 0,
        canvasWidth: 300,
        canvasHeight: 500,
        imageWidth: 0,
        imageHeight: 0,
      };
    },
    props: {
      canvasImage: {
        type: String,
        required: true,
      },
      documentTitle: {
        type: String,
        default: 'Título do Documento',
      },
      width: {
        type: Number,
        default: 200,
      },
      height: {
        type: Number,
        default: 500,
      },
      frontBackPrint: {
        type: Boolean,
        default: false,
      },
      linkToStock: {
        type: Boolean,
        default: false,
      },
      createLayout: {
        type: Number,
        default: 0,
      },
      layoutId: {
        type: Number,
        default: null,
      },
      clone: {
        type: Boolean,
        default: false,
      },
      conversionXFactor: {
        type: Number,
        default: 0.264589,
      },
      conversionYFactor: {
        type: Number,
        default: 0.264594,
      },
    },
    watch: {
      layoutId: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.insertedFields = [];
            try {
              this.$store.dispatch('loading/toggleLoading');
              const id = Number(this.layoutId);
              const result = await axios({
                url: `/layout/${id}`,
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'GET',
                responseType: 'json',
                withCredentials: true,
              });
              const layout = result.data.layout; //eslint-disable-line
              this.cloneImage = layout.background_image;
              this.layoutIdentifierField = Number(layout.key_field_id);
              this.cloneWidth = Number(layout.width);
              this.cloneHeight = Number(layout.height);
              this.canvasWidth = (Number(layout.width) * this.conversionXFactor).toFixed(2);
              this.canvasHeight = (Number(layout.height) * this.conversionYFactor).toFixed(2);
              this.imageWidth = Number(layout.width * this.conversionXFactor).toFixed(2);
              this.imageHeight = Number(layout.height * this.conversionYFactor).toFixed(2);
              this.organizeFields(layout.layout_fields);
              this.internalSettings.width = Number(layout.sheet_internal_width);
              this.internalSettings.height = Number(layout.sheet_internal_height);
              this.internalSettings.marginLeft = Number(layout.sheet_internal_margin_left);
              this.internalSettings.marginRight = Number(layout.sheet_internal_margin_right);
              this.internalSettings.marginTop = Number(layout.sheet_internal_margin_top);
              this.internalSettings.marginBottom = Number(layout.sheet_internal_margin_bottom);
              this.internalSettings.distanceToLeftDocument = Number(layout.distance_to_left_document);
              this.internalSettings.distanceToBottomDocument = Number(layout.distance_to_bottom_document);
              this.externalSettings.marginLeft = Number(layout.sheet_external_margin_left);
              this.externalSettings.marginRight = Number(layout.sheet_external_margin_right);
              this.externalSettings.marginTop = Number(layout.sheet_external_margin_top);
              this.externalSettings.marginBottom = Number(layout.sheet_external_margin_bottom);
              this.printSettings.documentsPerPage = Number(layout.documents_per_page);
              this.printSettings.documentsPerLine = Number(layout.documents_per_line);

              this.loadImage();
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
            }
          }
        },
      },
      createLayout: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.cloneLayout = Number(this.createLayout);
          }
        },
      },
      width: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.canvasWidth = (this.width * this.conversionXFactor).toFixed(2);
            this.imageWidth = this.width;
          }
        },
      },
      height: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.canvasHeight = (this.height * this.conversionYFactor).toFixed(2);
            this.imageHeight = this.height;
          }
        },
      },
      imageWidth: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.canvasWidth = (this.imageWidth / this.conversionXFactor).toFixed(2);
            this.loadImage();
          }
        },
      },
      imageHeight: {
        immediate: true,
        handler: async function (current) {
          if (current) {
            this.canvasHeight = (this.imageHeight / this.conversionYFactor).toFixed(2);
            this.loadImage();
          }
        },
      },
    },
    methods: {
      openDialog () {
        this.dialog = true;
        this.$nextTick(() => {
          this.loadImage();
          this.clone ? this.title = 'Título do Layout' : this.title = this.documentTitle; //eslint-disable-line
          this.imageWidth = (this.width * this.conversionXFactor).toFixed(2);
          this.imageHeight = (this.height * this.conversionYFactor).toFixed(2);
          this.canvasWidth = (this.width / this.conversionXFactor).toFixed(2);
          this.canvasHeight = (this.height / this.conversionYFactor).toFixed(2);
        });
      },
      loadImage () {
        const canvas = this.$refs.canvasLayout;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          const img = new Image();
          if (this.cloneImage) {
            img.src = this.cloneImage;
            img.onload = () => {
              ctx.drawImage(img, 0, 0, (this.canvasWidth), (this.canvasHeight));
            };
            img.onerror = (err) => {
              console.log('Image Load Error: ', err); /* eslint-disable-line no-console */
            };
          } else {
            img.src = this.canvasImage;

            img.onload = () => {
              ctx.drawImage(img, 0, 0, (this.canvasWidth), (this.canvasHeight));
            };
            img.onerror = (err) => {
              console.log('Image Load Error: ', err); /* eslint-disable-line no-console */
            };
          }
        } else {
          console.log('Canvas is not available'); /* eslint-disable-line no-console */
        }
      },
      organizeFields (fields) {
        this.insertedFields = [];
        this.textareas = [];
        this.titles = [];
        this.imageFields = [];
        const organizedFields = [];
        fields.forEach((field) => {
          const layoutField = {
            x: Number(field.x),
            y: Number(field.y),
            absolute_x: Number(field.absolute_x),
            absolute_y: Number(field.absolute_y),
            width: Number(field.width),
            height: Number(field.height),
            width_mm: Number(field.width_mm),
            height_mm: Number(field.height_mm),
            type_id: Number(field.type_id),
            font_size: Number(field.font_size),
            font: field.font,
            font_weight: field.font_weight,
            text_align: field.text_align,
            white_space: field.white_space,
            color: field.color,
            line_height: field.line_height,
            name: field.name,
            character_limit: Number(field.character_limit),
            is_checked: false,
            break_line_after: Number(field.break_line_after),
            rotate: Number(field.rotate),
            letter_spacing: Number(field.letter_spacing),
            default_url: field.default_url,
            description: field.description,
            upper_case: field.upper_case,
            border_radius: Number(field.border_radius),
          };

          if (Number(this.cloneLayout) === 2) {
            layoutField.layout_id = Number(field.layout_id);
            layoutField.id = Number(field.id);
            layoutField.creator_id = Number(field.creator_id);
          }
          const dictionary = [];

          if (field.layout_field_dictionary) {
            field.layout_field_dictionary.forEach((codeItem) => {
              const codeObject = {
                code: codeItem.code,
                description: codeItem.description,
              };
              if (Number(this.cloneLayout) === 2) {
                codeObject.id = Number(codeItem.id);
                codeObject.layout_field_id = Number(codeItem.layout_field_id);
              }

              dictionary.push(codeObject);
            });
            layoutField.code_dictionary = dictionary;
          }
          if (layoutField.type_id === 1) {
            this.titles.push(layoutField);
          } else if (layoutField.type_id === 2) {
            if (Number(field.id) === this.layoutIdentifierField) {
              this.checkboxIndex = this.textareas.length;
              layoutField.is_checked = true;
            }
            this.textareas.push(layoutField);
          } else if (layoutField.type_id === 3) {
            this.imageFields.push(layoutField);
          }

          organizedFields.push(layoutField);
        });
        this.insertedFields = organizedFields;
      },
      addTextarea (fieldType) {
        const canvas = this.$refs.canvasLayout;
        const canvasRect = canvas.getBoundingClientRect();
        const canvasContainer = canvas.parentElement;
        const containerRect = canvasContainer.getBoundingClientRect();
        const textarea = {
          x: canvasRect.left - containerRect.left,
          y: canvasRect.top - containerRect.top,
          absolute_x: canvasRect.left - containerRect.left,
          absolute_y: canvasRect.top - containerRect.top,
          layout_id: Number(this.cloneLayout) === 2 ? this.layoutId : null,
          name: fieldType === 1 ? 'Título' : 'Texto',
          width: 150,
          height: 100,
          width_mm: 37.2,
          height_mm: 24.8,
          type_id: fieldType,
          rotate: 0,
          font: 'arial',
          font_size: 8,
          character_limit: '100',
          line_height: 1.5,
          font_weight: 'normal',
          color: '#000000',
          text_align: 'left',
          white_space: 'normal',
          is_checked: false,
          upper_case: false,
          letter_spacing: fieldType === 0 ? null : 1,
          break_line_after: null,
          code_dictionary: [],
          creator_id: 1,
        };

        if (fieldType === 2) {
          this.textareas.push(textarea);
        } else if (fieldType === 1) {
          this.titles.push(textarea);
        }
        this.insertedFields.push(textarea);
      },
      addDictionary (textarea) {
        textarea.code_dictionary.push({
          code: '',
          description: '',
        });
      },
      addImageField () {
        const canvas = this.$refs.canvasLayout;
        const canvasRect = canvas.getBoundingClientRect();
        const canvasContainer = canvas.parentElement;
        const containerRect = canvasContainer.getBoundingClientRect();
        const imageField = {
          x: canvasRect.left - containerRect.left,
          y: canvasRect.top - containerRect.top,
          absolute_x: canvasRect.left - containerRect.left,
          absolute_y: canvasRect.top - containerRect.top,
          width: 150,
          height: 200,
          width_mm: 37.2,
          height_mm: 49.6,
          layout_id: Number(this.cloneLayout) === 2 ? this.layoutId : null,
          name: 'Imagem',
          type_id: 3,
          rotate: 0,
          border_radius: 0,
          default_url: '',
          description: '',
          code_dictionary: [],
          creator_id: 1,
        };

        this.imageFields.push(imageField);
        this.insertedFields.push(imageField);
      },
      handleInputChange (textarea) {
        if (textarea.break_line_after && textarea.name.length === Number(textarea.break_line_after)) {
          textarea.name += '\n';
        }
      },
      deleteDictionaryItem (index, codeIndex) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja excluir item do dicionário?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.textareas[index].code_dictionary[codeIndex].id ? this.fieldsDictionaryDeleted.push(this.textareas[index].code_dictionary[codeIndex].id) : true; // eslint-disable-line
            this.textareas[index].code_dictionary.splice(codeIndex, 1);
          }
        });
      },
      deleteTextField (index) {
        if (index === this.checkboxIndex) {
          Swal.fire({
            icon: 'warning',
            title: 'Não é possível excluir um campo identificador.',
            text: 'Selecione outro campo para ser o campo identificador antes de excluir o campo atual',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            text: 'Tem certeza que deseja excluir o campo?',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              this.textareas[index].id ? this.fieldsDeleted.push(this.textareas[index].id) : true; // eslint-disable-line
              this.insertedFields = this.insertedFields.filter((item) => item !== this.textareas[index]);
              this.textareas.splice(index, 1);
            }
          });
        }
      },
      deleteImageField (index) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja excluir o campo?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.imageFields[index].id ? this.fieldsDeleted.push(this.imageFields[index].id) : true; // eslint-disable-line
            this.insertedFields = this.insertedFields.filter((item) => item !== this.imageFields[index]);
            this.imageFields.splice(index, 1);
          }
        });
      },
      deleteTitleField (index) {
        Swal.fire({
          icon: 'warning',
          text: 'Tem certeza que deseja excluir o campo?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.titles[index].id ? this.fieldsDeleted.push(this.titles[index].id) : true; // eslint-disable-line
            this.insertedFields = this.insertedFields.filter((item) => item !== this.titles[index]);
            this.titles.splice(index, 1);
          }
        });
      },
      fieldCSS (field) {
        const rotate = Number(field.rotate);
        return {
          left: `${field.x}px`,
          top: `${field.y}px`,
          width: `${field.width}px`,
          height: `${field.height}px`,
          transform: `rotate(${rotate}deg)`,
          position: 'absolute',
        };
      },
      textAreaCSS (textarea) {
        const minLineHeight = textarea.font_size * 0.5;
        return {
          fontFamily: textarea.font,
          fontSize: `${textarea.font_size}px`,
          fontWeight: `${textarea.font_weight}`,
          color: textarea.color,
          lineHeight: textarea.line_height || minLineHeight,
          letterSpacing: `${textarea.letter_spacing}px`,
          whiteSpace: textarea.white_space,
          textAlign: textarea.text_align,
          borderRadius: `${textarea.border_radius}px`,
          padding: '0',
          boxSizing: 'border-box',
        };
      },
      changeCheckbox (index) {
        if (index === this.checkboxIndex) {
          this.textareas[index].is_checked = false;
          this.checkboxIndex = -1;
        } else if (this.checkboxIndex > -1) {
          this.textareas[this.checkboxIndex].is_checked = false;
          this.checkboxIndex = index;
          this.textareas[index].is_checked = true;
        } else {
          this.textareas[index].is_checked = true;
          this.checkboxIndex = index;
        }
      },
      drag (event) {
        if (this.isDragging && this.currentTextarea) {
          const canvas = this.$refs.canvasLayout;
          const canvasRect = canvas.getBoundingClientRect();
          const canvasContainer = canvas.parentElement;
          const containerRect = canvasContainer.getBoundingClientRect();
          this.currentTextarea.x += event.movementX;
          this.currentTextarea.y += event.movementY;
          const calculateX = (this.currentTextarea.x - (canvasRect.left - containerRect.left)) * this.conversionXFactor;
          this.currentTextarea.absolute_x = Math.floor(calculateX * 10) / 10;
          const calculateY = (this.currentTextarea.y - (canvasRect.top - containerRect.top)) * this.conversionYFactor;
          this.currentTextarea.absolute_y = Math.floor(calculateY * 10) / 10;
        }
      },
      updatePositionFromInput (component) {
        const canvas = this.$refs.canvasLayout;
        const canvasRect = canvas.getBoundingClientRect();
        const canvasContainer = canvas.parentElement;
        const containerRect = canvasContainer.getBoundingClientRect();
        this.currentTextarea = component;
        this.currentTextarea.x = (this.currentTextarea.absolute_x / this.conversionXFactor) + (canvasRect.left - containerRect.left);
        this.currentTextarea.y = (this.currentTextarea.absolute_y / this.conversionYFactor) + (canvasRect.top - containerRect.top);
      },
      stopDrag () {
        this.isDragging = false;
        this.currentTextarea = null;
        document.removeEventListener('mousemove', this.drag);
        document.removeEventListener('mouseup', this.stopDrag); // eslint-diable-line
      },
      startDrag (event, textarea) {
        this.isDragging = true;
        this.currentTextarea = textarea;
        document.addEventListener('mousemove', this.drag);
        document.addEventListener('mouseup', this.stopDrag);
      },
      onCanvasMouseMove (event) {
        this.canvasX = event.offsetX;
        this.canvasY = event.offsetY;
      },
      resize (event) {
        if (this.isResizing && this.currentTextarea) {
          if (this.currentTextarea.rotate % 360 === 90) {
            this.currentTextarea.width += event.movementY;
            this.currentTextarea.height -= event.movementX;
          } else if (this.currentTextarea.rotate % 360 === 270) {
            this.currentTextarea.width -= event.movementY;
            this.currentTextarea.height += event.movementX;
          } else {
            this.currentTextarea.width += event.movementX;
            this.currentTextarea.height += event.movementY;
          }
        }
        const calculateWidth = this.currentTextarea.width * this.conversionXFactor;
        this.currentTextarea.width_mm = Math.floor(calculateWidth * 10) / 10;
        const calculateHeigh = this.currentTextarea.height * this.conversionYFactor;
        this.currentTextarea.height_mm = Math.floor(calculateHeigh * 10) / 10;
      },
      updateResizeFromInput (component) {
        this.currentTextarea = component;
        const calculateWidth = (this.currentTextarea.width_mm / this.conversionXFactor);
        this.currentTextarea.width = Math.floor(calculateWidth * 10) / 10;
        const calculateHeight = (this.currentTextarea.height_mm / this.conversionYFactor);
        this.currentTextarea.height = Math.floor(calculateHeight * 10) / 10;
      },
      stopResize () {
        this.isResizing = false;
        this.currentTextarea = null;
        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.stopResize);
      },
      startResize (event, textarea) {
        this.isResizing = true;
        this.currentTextarea = textarea;
        document.addEventListener('mousemove', this.resize);
        document.addEventListener('mouseup', this.stopResize);
      },
      resizeImage (event) {
        if (this.isResizingImage && this.currentImageResizable) {
          this.currentImageResizable.width += event.movementX;
          this.currentImageResizable.height += event.movementY;
          const calculateWidth = this.currentImageResizable.width * this.conversionXFactor;
          this.currentImageResizable.width_mm = Math.floor(calculateWidth * 10) / 10;
          const calculateHeight = this.currentImageResizable.height * this.conversionYFactor;
          this.currentImageResizable.height_mm = Math.floor(calculateHeight * 10) / 10;
        }
      },
      resizeImagefromInput (component) {
        this.currentImageResizable = component;
        this.currentImageResizable.width = (this.currentImageResizable.width_mm / this.conversionXFactor);
        this.currentImageResizable.height = (this.currentImageResizable.height_mm / this.conversionYFactor);
      },
      stopResizeImage () {
        this.isResizingImage = false;
        this.currentImageResizable = null;
        document.removeEventListener('mousemove', this.resizeImage);
        document.removeEventListener('mouseup', this.stopResizeImage);
      },
      startResizeImage (event, imageField) {
        event.preventDefault();
        event.stopPropagation();
        this.isResizingImage = true;
        this.currentImageResizable = imageField;
        document.addEventListener('mousemove', this.resizeImage);
        document.addEventListener('mouseup', this.stopResizeImage);
      },
      closeDialog () {
        this.insertedFields = [];
        this.textareas = [];
        this.imageFields = [];
        this.titles = [];
        this.dialog = false;
        this.title = '';
        this.cloneLayout = null;
        this.cloneImage = null;
        this.$emit('dialog-closed');
      },
      saveSettings: async function () {
        const layout = {
          layout: {
            active: true,
            name: this.title,
            background_image: this.cloneImage ? this.cloneImage : this.canvasImage,
            width: Number(this.canvasWidth).toFixed(2),
            height: Number(this.canvasHeight).toFixed(2),
            front_back_print: this.frontBackPrint,
            save_stock: this.linkToStock,
            key_field_id: this.checkboxIndex > -1 ? this.checkboxIndex : null,
            sheet_internal_width: this.internalSettings.width,
            sheet_internal_height: this.internalSettings.height,
            sheet_internal_margin_left: this.internalSettings.marginLeft,
            sheet_internal_margin_right: this.internalSettings.marginRight,
            sheet_internal_margin_top: this.internalSettings.marginTop,
            sheet_internal_margin_bottom: this.internalSettings.marginBottom,
            distance_to_left_document: this.internalSettings.distanceToLeftDocument,
            distance_to_bottom_document: this.internalSettings.distanceToBottomDocument,
            sheet_external_margin_left: this.externalSettings.marginLeft,
            sheet_external_margin_right: this.externalSettings.marginRight,
            sheet_external_margin_top: this.externalSettings.marginTop,
            sheet_external_margin_bottom: this.externalSettings.marginBottom,
            documents_per_page: this.printSettings.documentsPerPage,
            documents_per_line: this.printSettings.documentsPerLine,
            fieldsDeleted: this.fieldsDeleted,
            fieldsDictionaryDeleted: this.fieldsDictionaryDeleted,
          },
          layout_fields: {
            inserted_fields: this.insertedFields,
          },
        };
        if (Number(this.cloneLayout) === 2) {
          layout.layout.id = this.layoutId;
          layout.layout.update = true;
        }
        await axios({
          url: Number(this.cloneLayout) === 2 ? '/layout/update' : '/layout',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: Number(this.cloneLayout) === 2 ? 'PUT' : 'POST',
          responseType: 'json',
          withCredentials: true,
          data: {
            layout,
          },
        });
        window.location.reload(true);
      },
      confirmSaveSettings: async function () {
        if (this.checkboxIndex <= Number(-1)) {
          Swal.fire({
            icon: 'warning',
            title: 'Não é possível criar o layout sem um campo indentificador',
            text: 'Escolha um dos campos como campo identificador.',
            confirmButtonText: 'Ok',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Deseja salvar alterações?',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Não',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.saveSettings();
              this.dialog = false;
              this.closeDialog();
            }
          });
        }
      },
    },
  };
</script>

<style>
 .canvas-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .v-expansion-panel-header {
    padding: 3px !important;
  }
  .block-title {
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .toolbar-title-input {
    font-size: 20px;
    width: 500px;
  }
  #canvasLayout {
    position: 'relative';
  }
  .title {
    max-width: 50%;
  }
  .full-height-card {
    height: 100%;
  }
  .buttons-list {
    margin-top: 20px;
  }
  .draggable {
    position: absolute;
    cursor: move;
  }
  textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    outline: none
  }
  .center-card {
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    border: 2px solid blue;
  }
  .card-title {
    text-align: center;
  }
  .form-button {
    width: 100%;
    padding: 25px !important;
    margin-bottom: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .button-text {
    width: 72%;
  }
  .resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    background-color: #ccc;
    cursor: se-resize;
  }
  .drag-handle {
    position: absolute;
    top: -16px;
    left: -16px;
    width: 20px;
    height: 20px;
    cursor: grab;
  }
  .drag-handle:active {
    cursor: grabbing !important;
  }
  .drag-handle .mdi {
    color: #ff7112;
    font-size: 19px;
  }
  .image-field {
    position: absolute;
    overflow: hidden;
    border: 1px solid #000;
    cursor: pointer;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-field img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .file-input {
    display: none;
  }
  .drag-handle-image {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 20px;
    height: 20px;
    background-color: #ff7112;
    cursor: move;
  }

  @font-face {
  font-family: markpro;
  src: url(../../assets/fonts/Mark-Pro.ttf);
}

  @font-face {
    font-family: markprobold;
    src: url(../../assets/fonts/Mark-Pro-Bold.ttf);
  }

  @font-face {
    font-family: 'arial regular';
    src: url('../../assets/fonts/arial regular.ttf');
  }

  @font-face {
    font-family: 'tahoma';
    src: url(../../assets/fonts/tahoma.ttf);
  }

  @font-face {
    font-family: 'tahoma bold';
    src: url('../../assets/fonts/tahoma bold.ttf');
  }

  @font-face {
    font-family: crystal;
    src: url(../../assets/fonts/crystal.TTF);
  }

  @font-face {
    font-family: ocrbstd;
    src: url(../../assets/fonts/OCR_B_Std_Regular.otf);
  }
</style>
