<template>
  <v-container fluid>
    <v-card-title class='py-4'>
      <v-spacer />
      <v-text-field
        v-model='search'
        append-icon='mdi-magnify'
        :label='$t("SEARCH")'
        single-line
        hide-details
        clearable
      />
    </v-card-title>
    <v-data-table
      :search='search'
      :headers='headers'
      :items='customers'
      class='elevation-1'
    >
      <template #item.created_at='{ item }'>
        <v-tooltip top>
          <template #activator='{ on }'>
            <div v-on='on'>
              {{formattedDate(item.created_at)}}
            </div>
          </template>
        </v-tooltip>
      </template>
      <template #item.active='{ item }'>
        <v-tooltip top>
          <template #activator='{ on }'>
            <div v-on='on'>
              {{item.active? $t('ACTIVE') : $t('INACTIVE')}}
            </div>
          </template>
        </v-tooltip>
      </template>
      <template #item.actions='{ item }'>
        <v-icon color='secondary' small class='mr-2' @click='editItem(item)'>
          mdi-pencil
        </v-icon>
        <v-icon color='error' small @click='deleteItem(item)'>
          mdi-delete
        </v-icon>
      </template>
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title> {{$t('CUSTOMERS')}} </v-toolbar-title>
          <v-spacer />
          <v-icon class='mb-3 mr-3' @click='getCustomers()'>
            mdi-reload
          </v-icon>
          <v-dialog v-model='dialog' max-width='500px' @click:outside='editedItem = defaultItem'>
            <template #activator='{ on, attrs }'>
              <v-btn color='secondary' dark class='mb-2' v-bind='attrs' v-on='on'>
                {{$t('NEW_CUSTOMER')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class='text-h5'>{{formTitle}}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols='12'>
                      <v-text-field
                        v-model='editedItem.name'
                        :rules='[rules.required]'
                        :label='$t("NAME")'
                        clearable
                      />
                    </v-col>
                    <v-col cols='12' sm='6' md='6'>
                      <v-text-field
                        v-model='editedItem.cnpj'
                        :label='$t("CNPJ")'
                        clearable
                      />
                    </v-col>
                    <v-col cols='12' sm='6' md='6'>
                      <v-text-field
                        v-model='editedItem.phone'
                        :label='$t("PHONE")'
                        clearable
                      />
                    </v-col>
                    <v-col cols='12' sm='9' md='9'>
                      <v-text-field
                        v-model='editedItem.email'
                        auto-complete='new-password'
                        :label='$t("EMAIL")'
                        clearable
                      />
                    </v-col>
                    <v-col cols='12' sm='3' md='3'>
                      <v-checkbox
                        v-model='editedItem.active'
                        :label='editedItem.active ? $t("ACTIVE") : $t("INACTIVE") '
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model='editedItem.description'
                        :label='$t("DESCRIPTION")'
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model='editedItem.initials'
                        :label='$t("INITIALS")'
                      />
                    </v-col>
                    <v-col cols='12'>
                      <v-checkbox
                        v-model='editedItem.faceMatch'
                        :label='$t("FACE_MATCH")'
                      />
                    </v-col>
                    <v-col cols='12'>
                      <v-text-field
                        v-model='editedItem.qrCodeBaseUrl'
                        :label='$t("QRCODE_BASE_URL")'
                        :hint='$t("QRCODE_BASE_URL_HINT")'
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model='editedItem.layouts'
                        :items='templates'
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        item-text='name'
                        item-value='id'
                        :label='$t("LAYOUTS")'
                        placeholder='Selecione um layout'
                      >
                        <template #selection='data'>
                          <v-chip
                            v-bind='data.attrs'
                            :input-value='data.selected'
                            color='primary'
                            close
                            @click='data.select'
                            @click:close='remove(data.item)'
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color='secondary' text @click='close'>
                  {{$t('CANCEL')}}
                </v-btn>
                <v-btn color='secondary' text @click='save'>
                  {{$t('SAVE')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { format, parseISO } from 'date-fns';

  export default {
    name: 'Customer',
    data: function () {
      return {
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
        },
        headers: [
          {
            text: this.$t('NAME'),
            align: 'center',
            value: 'name',
          },
          {
            text: this.$t('PHONE'),
            align: 'center',
            value: 'phone',
          },
          {
            text: this.$t('EMAIL'),
            align: 'center',
            value: 'email',
          },
          {
            text: this.$t('CREATION_DATE'),
            align: 'center',
            value: 'created_at',
          },
          {
            text: this.$t('STATUS'),
            align: 'center',
            value: 'active',
            sortable: false,
          },
          {
            text: this.$t('INITIALS'),
            align: 'center',
            value: 'initials',
            sortable: false,
          },
          {
            text: this.$t('ACTIONS'),
            align: 'center',
            value: 'actions',
            sortable: false,
          },
        ],
        customers: [],
        search: undefined,
        dialog: false,
        editedItem: {
          id: undefined,
          name: '',
          cnpj: '',
          phone: '',
          email: '',
          description: '',
          active: true,
          initials: '',
          qrCodeBaseUrl: '',
          layouts: [],
        },
        defaultItem: {
          id: undefined,
          name: '',
          cnpj: '',
          phone: '',
          email: '',
          description: '',
          active: true,
          initials: '',
          qrCodeBaseUrl: '',
        },
        templates: [],
      };
    },
    computed: {
      formTitle: function () {
        return this.editedItem.id === undefined ? this.$t('NEW_CUSTOMER') : this.$t('EDIT_CUSTOMER');
      },
    },
    mounted: function () {
      this.getCustomers();
      this.getAllTemplates();
    },
    methods: {
      getAllTemplates: async function () {
        this.recordList = [];
        this.templates = [];
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/layouts',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.templates = result.data.map((layout) => ({ id: layout.id, name: layout.name }));
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customers = result.data.customers;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      editItem: function (item) {
        this.editedItem = { ...item };
        this.editedItem.qrCodeBaseUrl = this.editedItem.qrcode_base_url;
        this.editedItem.faceMatch = this.editedItem.face_match;
        this.dialog = true;
      },
      deleteItem: function (item) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('WARNING'),
          text: this.$t('CONFIRM_DELETE_CUSTOMER', { customerName: item.name }),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: this.$t('CONFIRM'),
          cancelButtonText: this.$t('CANCEL'),
          reverseButtons: true,
        }).then(async (result) => {
          if (result.value) {
            try {
              this.$store.dispatch('loading/toggleLoading');
              await axios({
                url: '/customer',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'DELETE',
                data: {
                  id: item.id,
                },
                responseType: 'json',
                withCredentials: true,
              });
              this.getCustomers();
              this.$store.dispatch('loading/toggleLoading');
            } catch (e) {
              console.log(e); /* eslint-disable-line no-console */
              this.$store.dispatch('loading/toggleLoading');
            }
          }
        });
      },
      close: function () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = { ...this.defaultItem };
        });
        this.getCustomers();
      },
      save: function () {
        this.saveCustomer();
      },
      saveCustomer: async function () {
        const newCustomer = this.editedItem.id === undefined;
        let ids = [];
        if (typeof this.editedItem.layouts[0] === 'object' && this.editedItem.layouts[0] !== null) {
          ids = this.editedItem.layouts.map((item) => item.id);
        }
        this.editedItem.layouts = ids;
        try {
          this.$store.dispatch('loading/toggleLoading');
          await axios({
            url: '/customer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: newCustomer ? 'POST' : 'PUT',
            data: {
              id: this.editedItem.id,
              name: this.editedItem.name,
              cnpj: this.editedItem.cnpj,
              phone: this.editedItem.phone,
              email: this.editedItem.email,
              description: this.editedItem.description,
              active: this.editedItem.active,
              initials: this.editedItem.initials,
              qrCodeBaseUrl: this.editedItem.qrCodeBaseUrl,
              layouts: this.editedItem.layouts,
              faceMatch: this.editedItem.faceMatch,
            },
            responseType: 'json',
            withCredentials: true,
          });
          this.getCustomers();
          this.$store.dispatch('loading/toggleLoading');
          this.close();
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading');
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      remove (item) {
        const index = this.editedItem.layouts.findIndex((layout) => layout.id === item.id);
        if (index >= 0) {
          this.editedItem.layouts.splice(index, 1);
          this.editedItem.layouts = this.editedItem.layouts.map((layout) => layout.id);
        }
      },
    },
  };
</script>
