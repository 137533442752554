<template>
  <v-dialog v-model='previewDialog.show'>
    <v-card v-if='previewDialog.show' style='background-color: white; position: relative'>
      <v-app-bar dark color='primary'>
        <v-tooltip bottom>
          <template #activator='{ on }'>
            <v-btn icon v-on='on' @click.native='previewDialog.show = false'>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{$t('CLOSE')}}</span>
        </v-tooltip>

        <div class='ml-2 d-flex flex-column'>
          <div>
            <span class='text-h5'> Pré-visualização </span>
            <span style='font-size: 0.8em'> ({{ previewDialog.template.name }}) </span>
          </div>
          <span> {{ previewDialog.id }} </span>
        </div>
      </v-app-bar>
      <div class='d-flex justify-center pa-5' style=' width: 100%; height: 100%'>
        <Canvas :id='previewDialog.id' :values='previewDialog.values' :template='previewDialog.template' :template-layout='layout' />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: 'PreviewDialog',
    props: {
      templateLayout: {
        type: Object,
        default: () => {},
      },
      previewInfo: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      Canvas: () => import('../Canvas/CNH/Canvas'),
    },
    data: function () {
      return {
        layout: null,
        previewDialog: {},
      };
    },
    mounted: function () {
      this.layout = this.templateLayout;
      this.previewDialog = this.previewInfo;
    },
  };
</script>
